.nav-item{
    padding:0px 10px
}


.nav-item .nav-link:hover{
    background-color: #0470BC;
   
}
.slider{
  background-image: url('../../../public/bg-img.jpg');
}
.nav-link{
    font-size: 19px;
    font-weight: 400;
}
.dropdown-item{
  font-weight: 700 !important;
}
.dropbtn {
    color: rgba(0, 0, 0, 0.55);
    padding: 8px;
    font-size: 18px;
    border: none;
    background-color: inherit;
  }
  .navbar-light .navbar-nav .nav-link{
    color:#fff !important;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    top:56px;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn
  
  {
    background-color: #e21108;
    color:white;
    transition: 0.4s;
    border-radius: 5px;

}
.nav-link:hover{
    background-color: #e21108;
    color:white !important;
    transition: 0.4s;
    border-radius: 5px;
}
.btn-primary:hover{
  background-color: #e21108 !important;
  color:white !important;
  transition: 0.4s;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 19px !important;
}
.btn-primary:focus{
  border:none !important
}
.active{
    background-color: #243036;
    color:white !important;
    border-radius: 5px;
}
.carousel-indicators [data-bs-target] {
    width: 14px !important; 
    height:14px !important;
    border-radius: 50%;
    background-color: black !important;
    margin-top: 20px;
  
   
}
.carousel-indicators .active{
   background-color:red !important;
}
.whatsapp-button {
  position: fixed;
  bottom: 30px; 
  right: 22px; 
  background-color: #4bbb74;
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0px 4px 8px 5px rgba(0, 0, 0, 0.2);
  z-index: 999; 
}

.whatsapp-button img {
  width: 50px; 
  height: 50px;
  border-radius: 50%;
}
.card1{
  transition: 0.5s all;
  cursor: pointer;
}
.card1:hover{
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.carousel-control-prev-icon,.carousel-control-next-icon{
  width: 3rem !important;
  height: 3rem !important;
  background-color: #e21108;
  border-radius: 50%;
}
.careers_bg{
  background-image: url('../../../public/Assets/Career-Banner.jpg');
  background-position: center;
  background-size: cover;
  height:280px
}
.careers_bg1{
  background-image: url('../../../public/Assets/Contact-us.jpg');
  background-position: center;
  background-size: cover;
  height:280px
}
.careers_bg2{
  background-image: url('../../../public/Assets/Eng-Auto.jpg');
  background-position: center;
  background-size: cover;
  height:280px
}
.breadcrumb{
  justify-content: center;
  align-items: center;
  height:240px
}
.navlink .nav-link{
  padding: 8px 16px !important;
}
.btn-primary{
  background-color: inherit !important;
  border: none !important;
  color:rgba(0, 0, 0, 0.55) !important;
  font-size: 19px !important;
}
.nav-link .active{
   color:#e21108 !important
}

.nav-item .nav-link:hover{
  background-color: #000080 !important;
  color:#fff !important;
}

.auto-img{
  background-image: url('../../../public/Assets/2.jpg');
  background-position: center;
  background-size: cover;
  height:150px;
  
}
.dropdown-item{
  font-size: 18px;
}
.container-fluid{
  padding: 0 !important;
}
#dropdown-basic-button{
  color:#fff !important
}
/* for mobile responsive */

@media(max-width:1024px){
    .res-mob{
      position: static !important;
      width: auto !important;
      margin-bottom: 15px;
    }
    .test-res{
      height: auto !important;
    }
}

@media(max-width:991px){
   #start-nav{
    display: flex;
    justify-content: start !important;
   }
}
@media(min-width:325px) and (max-width:425px){
       .res-mob{
        width: auto !important;
       }
       .prd-img{
         width:300px !important;
         margin-bottom: 20px;
       }
       body{
        overflow-x: hidden !important;
      }
      
}

@media(min-width:302px) and (max-width:500px){
  .dropdown-item{
          font-size: 13px;
  }
  
 
}
@media (max-width:600px){
  .res-bg{
    width: 95% !important;
  }
  .res-comlogo{
    width: 231px !important;
    padding: 12px 3px;
  }
  .res-title{
     display: none;
  }
  #mob-resp,#mob-resp-1,#mob-resp-2{
    width: 100% !important;
    height: 226px !important;
  }
  
  .header-nav{
    justify-content: start !important;

  }
  
  .btn-primary{
    margin-left: 6px;
    color:#fff !important
  }
  .cmp-logo{
    width: 303px !important;
  }
  .carousel-control-prev-icon, .carousel-control-next-icon{
    width: 2rem !important;
    height:2rem !important
  }
  .ele-submenu{
    flex-direction: column;
  }
 
}

.ele-submenu{
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding:12px 1px !important;
  align-items: center;
  margin-top: 10px;
}

.nav-power-items{
   text-decoration: none;
   font-size:22px;
   color:gray;
   font-weight: bold;
  
   
}


.dropdown-toggle{
  font-weight: 700 !important;
}


.success-message {
  background-color: #4CAF50; /* Green background color */
  color: white; /* White text color */
  padding: 10px; /* Padding around the message */
  border-radius: 4px; /* Rounded corners */
  margin-bottom: 10px; /* Add some space below the message */
  text-align: center; /* Center the text */
  font-size: 16px; /* Adjust the font size as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a shadow for emphasis */
}

/* Add a transition for smooth appearance and disappearance */
.success-message {
  transition: opacity 0.5s, transform 0.5s;
}

/* Define the style when it's hidden */
.success-message.hidden {
  opacity: 0;
  transform: translateY(-20px);
  pointer-events: none; /* Prevent interactions with hidden message */
}
body{
  overflow-x: hidden;
}
.navbar-light .navbar-toggler{
  background-color: white;
}
.btn-primary{
  color:#fff !important
}

/* Example media query targeting smaller screens */
@media only screen and (max-width: 400px) {
  body{
    overflow-x: hidden !important; /* Prevent horizontal scrolling */
  }

}


html, body {
  overflow-x: hidden;
}
